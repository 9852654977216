import { UpdateCardDetailsPage } from 'components/pages/UpdateCardDetailsPage'
import React from 'react'
import Layout from '../../layouts/layout'

const UpdateCardDetailsPageWrapper = () => {
  if (typeof window === 'undefined') return null

  return (
    <Layout>
      <UpdateCardDetailsPage />
    </Layout>
  )
}

export default UpdateCardDetailsPageWrapper
