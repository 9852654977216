import { useMutation, useQuery } from '@apollo/client'
import { useLocation } from '@reach/router'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PageLoader from 'components/UI/PageLoader'
import { navigate } from 'gatsby'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import config from '../../../config'
import { UpdateCardDatailsForm } from './components/UpdateCardDetailsForm'

const Wrapper = styled.div`
  padding-top: 40px;
  color: #fff;
  font-family: 'Rammetto One', cursive;
  h1 {
    font-weight: normal;
  }
  .card-list--card {
    background: #701ea8;
    display: inline-flex;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
  }

  .update-card-section {
    display: flex;
    justify-content: center;
  }
`

const UpdateCardDetailsPageWithStripe = () => {
  const elements = useElements()
  const stripe = useStripe()
  const [cardDetailsError, setCardDetailsError] = useState<string | null>(null)
  const { loading } = useQuery(gql`
    query getPaymentDetails {
      me {
        _id
        subscription {
          id
          customer {
            id
            paymentMethods {
              id
              card {
                brand
                last4
              }
              default
            }
          }
        }
      }
    }
  `)

  const onSubmit = async () => {
    if (!elements) return
    const { error } = await elements.submit()
    if (error) {
      setCardDetailsError(error.message || 'An unknown error occured')
      return
    }
    const result = await stripe?.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/account/update-card-details`,
      },
    })
    if (result?.error) {
      setCardDetailsError(result.error.message || 'An unknown error occured')
    }
  }

  if (loading) return <PageLoader />
  return (
    <Wrapper>
      <div className="update-card-section">
        <UpdateCardDatailsForm error={cardDetailsError} onSubmit={onSubmit} />
      </div>
    </Wrapper>
  )
}

export const UpdateCardDetailsPage = () => {
  const stripePromise = loadStripe(config.stripeKey)
  const location = useLocation()
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [setupIntent, setSetupIntent] = useState<string | null>(null)

  const [createSetupIntent] = useMutation(gql`
    mutation customer_createSetupIntent {
      customer_createSetupIntent {
        clientSecret
      }
    }
  `)

  const [updateCard, { loading: updatingCard }] = useMutation(gql`
    mutation customer_updateCard($setupIntentId: String!) {
      customer_updateCard(setupIntentId: $setupIntentId) {
        id
        card {
          brand
          last4
        }
        default
      }
    }
  `)

  const init = async () => {
    const { data } = await createSetupIntent()
    if (data.customer_createSetupIntent) {
      setClientSecret(data.customer_createSetupIntent.clientSecret)
      const searchParams = new URLSearchParams(location.search)
      searchParams.set(
        'setup_intent_client_secret',
        data.customer_createSetupIntent.clientSecret
      )
      navigate(`${location.pathname}?${searchParams.toString()}`)
    }
  }

  const handleSetupIntentResult = async () => {
    const urlParams = new URLSearchParams(location.search)
    const setupIntent = urlParams.get('setup_intent')
    setSetupIntent(setupIntent)
    const { data } = await updateCard({
      variables: {
        setupIntentId: setupIntent,
      },
    })
    if (data.customer_updateCard?.id) {
      navigate('/account?forceRefetch=true')
    }
  }

  const handleSetupIntentCreated = () => {
    const urlParams = new URLSearchParams(location.search)
    const clientSecret = urlParams.get('setup_intent_client_secret')
    setClientSecret(clientSecret)
  }

  const handleSetupIntentNotCreated = () => {
    init()
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const setupIntent = urlParams.get('setup_intent')
    const setupIntentClientSecret =
      urlParams.get('setup_intent_client_secret') ||
      urlParams.get('client_secret')

    if (setupIntent && setupIntentClientSecret) {
      handleSetupIntentResult()
    } else if (setupIntentClientSecret) {
      handleSetupIntentCreated()
    } else {
      handleSetupIntentNotCreated()
    }
  }, [])

  if (updatingCard) return <PageLoader />
  if (setupIntent) return <PageLoader />
  if (!clientSecret) return <PageLoader />
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
      }}
    >
      <UpdateCardDetailsPageWithStripe />
    </Elements>
  )
}
